import {
  CheckIcon,
  EditIcon,
  ShareIcon,
  ViewIcon,
} from '@/react/componentAssets'
import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import classNames from 'classnames'
import moment from 'moment'
import React, { useState } from 'react'
import Highlighter from 'react-highlight-words'

export const ObservationRow = ({
  observation,
  index,
  selectedIds,
  setSelectedIds,
  setAllChecked,
  search,
  page,
}) => {
  const { Api, CurrentUser, Notification } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const { stateService } = useRouter()

  const [hoveredField, setHoveredField] = useState(null)
  const [isLinkCopied, setLinkCopied] = useState(false)

  const { name: category } = observation?.category
  const { first_name, last_name, company_name } = observation?.user
  const { name: project } = observation?.project
  const { date_created, client_object_key } = observation

  const handleCopyLink = () => {
    const currentURL = window.location.href
    const urlObject = new URL(currentURL)
    const host = urlObject.host

    navigator.clipboard.writeText(
      `https://${host}/share/observation/${client_object_key}`,
    )

    setLinkCopied(true)
  }

  const handleChecked = (id) => {
    setAllChecked(false)
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((sId) => sId !== id))
    } else {
      setSelectedIds([...selectedIds, id])
    }
  }

  return (
    <>
      <tr
        className={classNames('view__row', {
          dark: index % 2 === 0,
        })}
      >
        <td className="checkmark__td">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={selectedIds.includes(observation?.id)}
              onChange={() => {
                handleChecked(observation?.id)
              }}
            />
            <span className="checkmark">
              <ThinCheckmark color={variableColor} />
            </span>
          </label>
        </td>
        <td
          className="title__td"
          onMouseOver={() => {
            setHoveredField({
              field__name: 'category__fullname',
              text: category,
              max__length: 35,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="table__text category">
            <Highlighter
              highlightClassName={'highlighted__item'}
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={category}
            />
          </p>
        </td>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'originator__fullname',
              text: `${first_name} ${last_name}`,
              max__length: 18,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="table__text originator">
            <Highlighter
              highlightClassName={'highlighted__item'}
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={`${first_name} ${last_name}`}
            />
          </p>
        </td>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'company__fullname',
              text: company_name,
              max__length: 25,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="table__text company">
            <Highlighter
              highlightClassName={'highlighted__item'}
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={company_name}
            />
          </p>
        </td>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'project__fullname',
              text: project,
              max__length: 20,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="table__text project">
            <Highlighter
              highlightClassName={'highlighted__item'}
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={project}
            />
          </p>
        </td>
        <td>{moment(date_created).format('MM/DD/YYYY')}</td>
        <td
          className="icon__td"
          onClick={() => {
            stateService.go('app.observations.view', {
              observation: observation?.id,
              previousSearch: search,
              previousPageNumber: page,
            })
          }}
        >
          <ViewIcon color={variableColor} />
        </td>
        <td
          className="icon__td"
          onClick={() => {
            stateService.go('app.observations.edit', {
              observation: observation?.id,
              previousSearch: search,
              previousPageNumber: page,
            })
          }}
        >
          <EditIcon color={variableColor} />
        </td>
        <td
          onClick={handleCopyLink}
          onMouseOut={() => {
            setLinkCopied(false)
          }}
          className="share__td"
        >
          <ShareIcon
            height="30px"
            width="30px"
            color={variableColor}
            additionalStyles={{
              position: 'relative',
            }}
          />
          {isLinkCopied && (
            <div className="sucess__copy">
              <CheckIcon width="11" height="8" color="green" />
              <p className="copy__message">URL copied to clipboard</p>
            </div>
          )}
        </td>
      </tr>
      {hoveredField?.text?.length > hoveredField?.max__length && (
        <div className={`full__name ${hoveredField?.field__name}`}>
          {hoveredField?.text}
        </div>
      )}
    </>
  )
}
