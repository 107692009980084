import {
  ButtonElement,
  CommonPageHeader,
  PageSwitcher,
  useAngularServices,
} from '@/react/components'
import { ObservationNavBar } from '../components/ObservationNavBar'
import { css } from '@emotion/react'
import { useEffect, useMemo, useState } from 'react'
import { TableHeaders } from '@/react/components'
import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { singleHeaderTypes } from '../../components'
import { useRouter } from '@/react/hooks'
import { FinalizeRow } from './components/FinalizeRow'
import { PartyObservedModal } from './components/PartyObservedModal/PartyObservedModal'
import classNames from 'classnames'
import pluralize from 'pluralize'
import { checkIsSafari } from '@/utils/checkIsSafari'
import { DataWrap, PaginatedGet } from '@/react/types'

export const CombinedObservationsFinalize = () => {
  const { stateService } = useRouter()
  const { Api, CurrentUser, $rootScope } = useAngularServices()

  const wholeIdsList = useMemo(() => {
    return stateService.params?.f_id?.split(',')?.map((id) => Number(id))
  }, [])

  const [title, setTitle] = useState<string>('')
  const [order, setOrder] = useState<string>('-date_created')
  const [page, setPage] = useState<number>(1)
  const [count, setCount] = useState<number>(20)
  const [observationList, setObservationList] = useState<number[]>([])
  const [selectedIds, setSelectedIds] = useState<number[]>(wholeIdsList || [])

  const [selectedParties, setSelectedParties] = useState<number[]>([])
  const [partyObservedCount, setPartyObservedCount] = useState<number>(10)
  const [partySearch, setPartySearch] = useState<string>('')
  const [partyOrder, setPartyOrder] = useState<string>('name')
  const [partyObservedList, setPartyObservedList] = useState<number[]>([])
  const [isPartyObservedModalOpen, setPartyObservedModalOpen] =
    useState<boolean>(false)
  const [isNaAllowed, setNaAllowed] = useState<boolean>(false)

  const [idChangesDetected, setIdChangesDetected] = useState(true)

  const variableColor: string =
    CurrentUser.getClientSettings().web_primary_color

  const configurableNamesList = useMemo(() => {
    return $rootScope.mobile_apps.find(
      (obj) => obj.id === Number(stateService.params.app),
    )
  }, [$rootScope])

  useEffect(() => {
    const getObservations = async () => {
      const { data }: DataWrap<PaginatedGet<number[]>> = await Api.get(
        'observations',
        {
          deleted: false,
          observations: stateService?.params?.f_id,
          order,
          page,
          search: '',
        },
      )

      setObservationList(data?.results)
      setCount(data?.count)
    }

    getObservations()
  }, [page, order])

  useEffect(() => {
    const getPartyObservedList = async () => {
      const { data }: DataWrap<number[]> = await Api.post(
        'answers/unique_party_observed',
        {
          observations: selectedIds,
          search: partySearch,
        },
      )

      setPartyObservedList(data)
      setPartyObservedCount(data?.length)

      if (idChangesDetected) {
        setSelectedParties(data)
        setNaAllowed(true)
        setIdChangesDetected(false)
      }
    }

    getPartyObservedList()
  }, [partySearch, partySearch, selectedIds])

  const handlePartyOrderChange = (partyOrder: string) => {
    const sortedParties = partyObservedList.sort((a: string, b: string) => {
      const aSelected = selectedParties.includes(a)
      const bSelected = selectedParties.includes(b)

      if (partyOrder === 'active') {
        if (aSelected && !bSelected) {
          return -1
        }
        if (!aSelected && bSelected) {
          return 1
        }
      } else if (partyOrder === '-active') {
        if (aSelected && !bSelected) {
          return 1
        }
        if (!aSelected && bSelected) {
          return -1
        }
      }

      if (partyOrder === 'name') {
        if (a.toLowerCase() < b.toLowerCase()) {
          return -1
        }
        if (a.toLowerCase() > b.toLowerCase()) {
          return 1
        }
      } else if (partyOrder === '-name') {
        if (a.toLowerCase() > b.toLowerCase()) {
          return -1
        }
        if (a.toLowerCase() < b.toLowerCase()) {
          return 1
        }
      }

      return 0
    })

    setPartyObservedList(sortedParties)
  }

  const handleAllChecked = () => {
    if (wholeIdsList.length !== selectedIds?.length) {
      setSelectedIds(wholeIdsList)
      setIdChangesDetected(true)
    } else {
      setSelectedIds([])
      setIdChangesDetected(true)
    }
  }

  const handleChecked = (id) => {
    if (selectedIds?.includes(id)) {
      setSelectedIds(selectedIds?.filter((sId) => sId !== id))
      setIdChangesDetected(true)
    } else {
      setSelectedIds([...selectedIds, id])
      setIdChangesDetected(true)
    }
  }

  const handleCombine = async () => {
    await Api.post('batch_reports', {
      title: title,
      allow_no_party_observed: isNaAllowed,
      application: stateService?.params?.app,
      observations: selectedIds,
      parties_observed: selectedParties.map((party) => ({
        name: party,
      })),
    })
    stateService.go('app.batch_reports.list')
  }

  const onBackClick = () => {
    stateService.go('app.observations.list', {
      page: stateService?.params?.previousPage,
      order: stateService?.params?.previousOrder,
      search: stateService?.params?.previousSearch,
    })
  }

  const TABLE_HEADER_SETTINGS: singleHeaderTypes[] = [
    {
      className: 'table__header',
      name: '',
      type: 'checkbox',
      additionalStyles: {
        paddingLeft: '15px',
        minWidth: '50px',
        width: '50px',
        textAlign: 'left',
        borderBottom: '2px solid rgb(198, 198, 198)',
        p: {
          width: '0px',
        },
        '.header-base': {
          width: '0px',
          margin: 0,
          padding: 0,
        },
        '.header-actions': {
          display: 'flex',
          gap: '8px',
          position: 'relative',
        },
      },
      additionalInnerElement: (
        <label className="checkbox-container">
          <input
            type="checkbox"
            checked={wholeIdsList?.length === selectedIds?.length}
            onChange={handleAllChecked}
          />
          <span className="checkmark">
            <ThinCheckmark color={variableColor} />
          </span>
        </label>
      ),
    },
    {
      className: 'table__header title__column',
      name: 'Category',
      type: 'category__name',
      additionalStyles: {
        minWidth: '249px',
        width: '249px',
        maxWidth: '136px',
        textAlign: 'left',
        paddingLeft: '15px !important',
        padding: 0,
        borderBottom: '2px solid rgb(198, 198, 198)',
        p: {
          display: 'flex',
          width: 'auto',
        },
        '.header-base': {
          width: 'fit-content',
          display: 'flex',
        },
        '.header-sort-arrow': {
          marginLeft: '3px',
        },
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '170px',
          },
        },
      },
    },
    {
      className: 'table__header',
      name: 'Originator',
      type: 'user__first_name',
      additionalStyles: {
        minWidth: '136px',
        width: '136px',
        maxWidth: '136px',
        padding: 0,
        textAlign: 'left',
        borderBottom: '2px solid rgb(198, 198, 198)',
        p: {
          display: 'flex',
          width: 'auto',
        },
        '.header-base': {
          width: 'fit-content',
        },
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '170px',
          },
        },
      },
    },
    {
      className: 'table__header',
      name: 'Company',
      type: 'user__company_name',
      additionalStyles: {
        minWidth: '172px',
        width: '172px',
        maxWidth: '172px',
        padding: 0,
        borderBottom: '2px solid rgb(198, 198, 198)',
        textAlign: 'left',
        p: {
          display: 'flex',
          width: 'auto',
        },
        '.header-base': {
          width: 'fit-content',
        },
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '100px',
          },
        },
      },
    },
    {
      className: 'table__header',
      name: configurableNamesList?.project,
      type: 'project__name',
      additionalStyles: {
        minWidth: '151px',
        width: '151px',
        maxWidth: '151px',
        padding: 0,
        textAlign: 'left',
        borderBottom: '2px solid rgb(198, 198, 198)',
        p: {
          display: 'flex',
          width: 'auto',
        },
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '130px',
          },
        },
      },
    },
    {
      className: 'table__header',
      name: 'Created',
      type: 'date_created',
      filterHeader: true,
      additionalStyles: {
        borderBottom: '2px solid rgb(198, 198, 198)',
        minWidth: '100px',
        width: '100px',
        padding: 0,
        textAlign: 'left',
        p: {
          display: 'flex',
          width: 'auto',
        },
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '100px',
          },
        },
      },
    },
  ]

  const isSafari = checkIsSafari()
  const styles = getStyles(isSafari)

  return (
    <section className="observation-finalize" css={styles}>
      <CommonPageHeader
        headerText={`${configurableNamesList?.observations}`}
        isAnalyticsIconVisible={true}
        iconLink="https://rtrspro.knowledgetransfer.us/#/workbooks/8/views"
      />
      <ObservationNavBar />
      <main className="page__wrapper">
        <div className="top__block">
          <p className="page__title">{`${pluralize.plural(
            configurableNamesList?.main_menu_batch_reporting,
          )} Step 2`}</p>
          <div className="buttons__wrapper">
            <ButtonElement text="Back" functionToTrigger={onBackClick} />
            <ButtonElement
              buttonType="submit"
              text="Combine"
              functionToTrigger={handleCombine}
              disabled={title.length > 64}
              additionalStyles={css({
                ':disabled': {
                  color: '#fff !important',
                  background: 'gray !important',
                },
              })}
            />
          </div>
        </div>
        <div className="add__title">
          <div className="input__wrapper">
            <input
              className={classNames('title__input', {
                error: title?.length > 64,
              })}
              placeholder={`${configurableNamesList?.main_menu_batch_reporting} Title (Optional)`}
              value={title}
              onChange={(e) => {
                setTitle(e.target.value)
              }}
            />
            <p className="input__limit">{64 - title.length}</p>
          </div>
          {isPartyObservedModalOpen && (
            <PartyObservedModal
              handleClose={() => {
                setPartyObservedModalOpen(false)
                handlePartyOrderChange('name')
                setPartyOrder('name')
                handlePartyOrderChange('name')
                setPartyOrder('name')
              }}
              partySearch={partySearch}
              setPartySearch={setPartySearch}
              partyOrder={partyOrder}
              setPartyOrder={setPartyOrder}
              isNaAllowed={isNaAllowed}
              setNaAllowed={setNaAllowed}
              setSelectedParties={setSelectedParties}
              selectedParties={selectedParties}
              partyObservedList={partyObservedList}
              handlePartyOrderChange={handlePartyOrderChange}
            />
          )}
          <p
            className="add__message"
            onClick={() => setPartyObservedModalOpen(true)}
          >
            {`${configurableNamesList?.observation_party_observed} Selected (${
              selectedParties.length === partyObservedCount &&
              partyObservedCount > 0
                ? 'All'
                : selectedParties.length
            })`}
          </p>
        </div>
        <table className="observations__table">
          <TableHeaders
            headers={TABLE_HEADER_SETTINGS}
            updateUrl={false}
            order={order}
            popUpLocation={false}
            setOrder={setOrder}
            callback={setOrder}
          />
          <tbody>
            {observationList?.map((observation, index) => (
              <FinalizeRow
                key={index}
                observation={observation}
                index={index}
                search={''}
                selectedIds={selectedIds}
                handleChecked={handleChecked}
              />
            ))}
          </tbody>
        </table>
        <div className="page-switcher-base">
          <PageSwitcher
            pageLength={20}
            listCount={count}
            currentPage={page}
            callback={setPage}
            updateUrl={false}
          />
        </div>
      </main>
    </section>
  )
}

const getStyles = (isSafari) =>
  css({
    paddingBottom: '200px',
    '.page__wrapper': {
      background: '#fff',
      width: 'fit-content',
      paddingRight: '20px',
      paddingBottom: '20px',
    },
    '.top__block': {
      display: 'flex',
      paddingTop: '20px',
      paddingInline: '20px',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '.page__title': {
      fontSize: '12px',
      fontWeight: 700,
      color: '#68696D',
      margin: 0,
    },
    '.buttons__wrapper': {
      display: 'flex',
      gap: '15px',
    },
    '.add__title': {
      marginLeft: '20px',
      marginTop: '3px',
      display: 'flex',
      gap: '15px',
      alignItems: 'center',
    },
    '.input__wrapper': {
      display: 'flex',
      alignItems: 'flex-end',
    },
    '.title__input': {
      width: '410px',
      height: '32px',
      border: '1px solid #CDCCCC',
      outline: 'none',
      paddingLeft: '15px',
      '::placeholder': {
        color: '#B1B1B1',
        fontSize: '12px',
      },
    },
    '.input__limit': {
      color: '#888888',
      fontSize: '10px',
      margin: 0,
      marginLeft: '3px',
    },
    '.add__message': {
      fontSize: '12px',
      color: '#3980CE',
      margin: 0,
      cursor: 'pointer',
    },
    '.checkbox-container': {
      display: 'inline-block',
      position: 'relative',
      cursor: 'pointer',
      paddingLeft: '15px',
      margin: 0,
      height: '20px',
    },
    '.checkmark': {
      height: '22px',
      width: '22px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#FAFAFA',
      border: '1px solid #B1B1B1',
      svg: {
        display: 'none',
      },
    },
    '.checkmark::after': {
      display: 'none !important',
    },
    'input:checked ~ .checkmark': {
      border: 'none',
      img: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
      svg: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
    },
    '.observations__table': {
      marginTop: '12px',
      marginLeft: '20px',
    },
    tr: {
      height: '40px',
    },
    td: {
      paddingRight: '10px',
    },
    '.checkmark__td': {
      paddingLeft: '15px',
      width: '50px',
    },
    '.title__td': {
      paddingLeft: '15px',
    },
    '.table__text': {
      maxHeight: '40px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      margin: 0,
      textOverflow: 'ellipsis',
    },
    '.category': {
      maxWidth: '249px',
      cursor: 'pointer',
      color: '#3980CE',
    },
    '.originator': {
      maxWidth: '136px',
    },
    '.company': {
      maxWidth: '172px',
    },
    '.project': {
      maxWidth: '151px',
    },
    '.created': {
      maxWidth: '120px',
    },
    '.view__row': {
      color: '#575757',
      position: 'relative',
    },
    '.dark': {
      background: '#F2F3F5',
      borderBottom: '1px solid #E7EAEC',
      borderTop: '1px solid #E7EAEC',
    },
    '.full__name': {
      display: isSafari ? 'none' : 'block',
      position: 'absolute',
      zIndex: 1,
      border: '1px solid #D3D3D3',
      boxShadow: '2px 4px 7px 0px rgba(0, 0, 0, 0.15)',
      background: 'white',
      left: '353px',
      padding: '4px 11px',
      maxWidth: '400px',
    },
    '.title__fullname': {
      left: '50px',
    },
    '.template__fullname': {
      left: '300px',
    },
    '.owner__fullname': {
      left: '530px',
    },
    '.company__fullname': {
      left: '430px',
    },
    '.project__fullname': {
      left: '650px',
    },
    '.error': {
      borderColor: '#BD2828',
    },
    '.page-switcher-base': {
      paddingLeft: '20px',
      paddingTop: '20px',
      div: {
        margin: 0,
      },
    },
    '@media(max-width:1135px)': {
      '.page__wrapper': {
        width: '100%',
        overflowX: 'scroll',
      },
      '.top__block': {
        flexDirection: 'column-reverse',
        alignItems: 'flex-start',
        gap: '10px',
      },
      '.add__title': {
        flexDirection: 'column',
        gap: '10px',
        alignItems: 'flex-start',
      },
      '.title__input': {
        width: '251px',
      },
    },
  })
