import * as React from 'react'
import { css } from '@emotion/react'
import { BesideInputButton } from '@/react/components'
import { TextInputStyle } from '@screens/components'

export function SingleNote({ notesLink, index, observationID }) {
  const [isDeleted, setIsDeleted] = React.useState(false)

  if (!notesLink[index]) {
    notesLink[index] = {
      observation: observationID,
    }
  }
  const [textareaHeight, setTextareaHeight] = React.useState<string | number>(
    '35',
  )
  const textareaRef: React.RefObject<HTMLInputElement> = React.useRef(null)

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      const lineHeight = 20
      const minRows = 1

      const rows = Math.max(
        minRows,
        Math.ceil(textareaRef.current.scrollHeight / lineHeight),
      )
      // - 6 is a style compensations for this specific field
      setTextareaHeight(rows * lineHeight - 6)
    }
  }

  React.useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus()
      adjustTextareaHeight()
    }
  }, [])

  if (isDeleted) return null

  return (
    <div css={baseStyle} className="single-global-note">
      <textarea
        className="form__textarea"
        ref={textareaRef}
        onFocus={adjustTextareaHeight}
        onInput={adjustTextareaHeight}
        defaultValue={notesLink[index].text ? notesLink[index].text : ''}
        onChange={(value) => {
          notesLink[index].text = value.target.value
        }}
        style={{ height: `${textareaHeight}px` }}
      />
      <BesideInputButton
        deleteButton={true}
        functionToTrigger={() => {
          setIsDeleted(true)
          notesLink[index].deleted = true
        }}
      />
      <hr />
    </div>
  )
}

const baseStyle = css({
  display: 'flex',
  marginBottom: 10,
  height: 'fit-content',
  input: {
    ...TextInputStyle,
    width: '90%',
  },
  '.form__textarea': {
    width: '100%',
    backgroundColor: '#FFFFFF',
    outline: 'none',
    backgroundImage: 'none',
    borderColor: '#e5e6e7',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: 0,
    paddingLeft: '6px',
    paddingRight: '6px',
    paddingTop: '6px',
    paddingBottom: '6px',
    fontSize: '14px',
    lineHeight: '20px',
    maxWidth: '100%',
    resize: 'vertical',
  },
})
