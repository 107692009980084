import * as React from 'react'
import { css } from '@emotion/react'

export function Wildcard({
  wildcard,
  setWildcard,
  wildcardHeader,
  required,
}: {
  wildcard: string
  setWildcard: (value: string) => void
  wildcardHeader: string
  required: boolean
}) {
  const [textareaHeight, setTextareaHeight] = React.useState<string | number>(
    '35',
  )
  const textareaRef: React.RefObject<HTMLInputElement> = React.useRef(null)

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      const lineHeight = 20
      const minRows = 1

      const rows = Math.max(
        minRows,
        Math.ceil(textareaRef.current.scrollHeight / lineHeight),
      )
      // - 6 is a style compensations for this specific field
      setTextareaHeight(rows * lineHeight - 6)
    }
  }

  React.useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus()
      adjustTextareaHeight()
    }
  }, [])

  return (
    <div css={baseStyle}>
      <label>
        <b>{wildcardHeader}</b>
      </label>
      <div className="base__textarea">
        <textarea
          className="form__textarea"
          ref={textareaRef}
          onFocus={adjustTextareaHeight}
          onInput={adjustTextareaHeight}
          defaultValue={wildcard ? wildcard : ''}
          onChange={(value) => {
            setWildcard(value.target.value)
          }}
          css={css({
            height: `${textareaHeight}px`,
            borderColor: required && wildcard.length === 0 ? 'red' : '#e5e6e7',
          })}
        />
      </div>
    </div>
  )
}

const baseStyle = css({
  alignItems: 'center',
  '.base__textarea': {
    minWidth: '300px',
    maxWidth: '100%',
  },
  '.form__textarea': {
    width: '100%',
    backgroundColor: '#FFFFFF',
    outline: 'none',
    backgroundImage: 'none',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: 0,
    paddingLeft: '6px',
    paddingRight: '6px',
    paddingTop: '6px',
    paddingBottom: '6px',
    fontSize: '14px',
    lineHeight: '20px',
    maxWidth: '100%',
    resize: 'vertical',
  },
})
