import * as React from 'react'
import { css } from '@emotion/react'
import pluralize from 'pluralize'

import { SingleNote } from './index'
import { useAngularServices } from '@/react/components'

export function NotesElement({
  notesVariableName,
  observationVariableName,
  notes,
  observationID,
}: {
  notesVariableName: string
  observationVariableName: string
  observationID: number
  notes: any[]
}) {
  const [notesElementList, setNotesElementList] = React.useState([])
  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  function addNoteElement(notes, observationID) {
    setNotesElementList([
      ...notesElementList,
      <SingleNote
        notesLink={notes}
        index={notesElementList.length}
        observationID={observationID}
        key={notesElementList.length}
        notesVariableName={notesVariableName}
      />,
    ])
  }

  React.useEffect(() => {
    const initialFields = []
    notes.forEach((inittial, idx) => {
      initialFields.push(<SingleNote key={idx} notesLink={notes} index={idx} />)
    })
    if (initialFields.length === 0) {
      initialFields.push(
        <SingleNote
          key={0}
          notesLink={notes}
          observationID={observationID}
          index={0}
        />,
      )
    }
    setNotesElementList(initialFields)
  }, [notes])

  return (
    <div css={baseStyle}>
      <label>
        <b>General {pluralize.singular(observationVariableName)} Note:</b>
        <a
          css={css({ color: variableColor })}
          onClick={() => {
            addNoteElement(notes, observationID)
          }}
        >
          {' + General Note'}
        </a>
      </label>
      <div className="base-notes">{notesElementList}</div>
    </div>
  )
}

const baseStyle = css({
  alignItems: 'center',
  '.base-notes': {
    minWidth: '300px',
    maxWidth: '100%',
    '.single-global-note:first-child:last-child .beside-input-button': {
      display: 'none',
    },
  },
})
