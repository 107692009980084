import { ReactWrapper } from '@components'

import { CreatePage } from './CreatePage'
import { ViewInternalPage } from '@screens/ToolboxTalks/ViewInternalPage'
import { ViewExternalPage } from '@screens/ToolboxTalks/ViewExternalPage'
import { ExternalSignatures } from '@screens/ToolboxTalks/ExternalSignatures'
import { DeletedListPage } from '@screens/ToolboxTalks/DeletedListPage'
import { ToolboxTalkList } from './ToolboxTalksList'
import { Assign } from './Assign'
import { AssignProject } from './AssignProject'
import { AssignProjectList } from './AssignProjectList'
import { AssignToProject } from './AssignToProject'
import { ToolboxTalksTemplates } from './ToolboxTalkTemplates'
import { ToolboxTemplateView } from './ToolboxTemplateView'
import { ToolboxTalkGroups } from './ToolboxTalkGroups'

export const createPageComponent = ReactWrapper(
  CreatePage,
  'toolboxTalksCreatePage',
)

export const viewInternalPage = ReactWrapper(
  ViewInternalPage,
  'toolboxTalksViewInternalPage',
)

export const viewExternalPage = ReactWrapper(
  ViewExternalPage,
  'toolboxTalksViewExternalPage',
)

export const externalSignaturesPage = ReactWrapper(
  ExternalSignatures,
  'toolboxTalksExternalSignaturesPage',
)

export const deletedListPage = ReactWrapper(
  DeletedListPage,
  'toolboxTalksDeletedListPage',
)
export const toolboxTalkListPage = ReactWrapper(
  ToolboxTalkList,
  'toolboxTalksListPage',
)
export const toolboxTalkAssign = ReactWrapper(Assign, 'toolboxTalkAssign')
export const toolboxTalkAssignProject = ReactWrapper(
  AssignProject,
  'toolboxTalkAssignProject',
)
export const toolboxTalkAssignProjectList = ReactWrapper(
  AssignProjectList,
  'toolboxTalkAssignProjectList',
)
export const toolboxTalkAssignToProject = ReactWrapper(
  AssignToProject,
  'toolboxTalkAssignToProject',
)
export const toolboxTalkTemplatesComponent = ReactWrapper(
  ToolboxTalksTemplates,
  'toolboxTalkTemplatesComponent',
)
export const toolboxTemplateViewComponent = ReactWrapper(
  ToolboxTemplateView,
  'toolboxTemplateViewComponent',
)
export const toolboxTalkGroupsComponent = ReactWrapper(
  ToolboxTalkGroups,
  'toolboxTalkGroupsComponent',
)
