angular
  .module('ccs')
  .controller(
    'QuestionsCtrl',
    function (
      $scope,
      $stateParams,
      $location,
      $q,
      Api,
      app,
      $state,
      CurrentUser,
      $log,
    ) {
      $log.error('QuestionsCtrl')

      this.$onInit = function () {
        $scope.categories = []
        $scope.category = {}
        $scope.questions = []
        $scope.appProjects = []
        $scope.pageSize = 20
        //Infinite Scroll Begin
        $scope.infiniteScroll = {}
        $scope.infiniteScroll.numToAdd = 20
        $scope.infiniteScroll.currentItems = 20

        $scope.resetInfScroll = function () {
          $scope.infiniteScroll.currentItems = $scope.infiniteScroll.numToAdd
        }

        $scope.addMoreItems = function () {
          $scope.infiniteScroll.currentItems += $scope.infiniteScroll.numToAdd
        }
        //Infinite Scroll End

        //Populate the search box
        $scope.query = $stateParams.search ? $stateParams.search : ''
        //The last executed query is used for highlighting (i.e. so highliting won't change until the user invokes search)
        $scope.lastExecutedQuery = $stateParams.search
          ? $stateParams.search
          : ''

        $scope.pageNumber = $stateParams.pageNumber
          ? $stateParams.pageNumber
          : 1
        //temporarily set the total number of questions to page size * page number (to initialize the uib-pagination before records are retreived )
        $scope.questionTotal = $scope.pageNumber * $scope.pageSize

        $scope.app = app
        const initialCategoryId = $stateParams.categoryId

        $scope.initialCategoryId = initialCategoryId

        getAppProjects(1)
      }

      $scope.userIs = CurrentUser.is

      function getParentCustomCategoryById(id) {
        Api.Categories.byID(id, (resp) => {
          $scope.category = {
            ...resp,
            activeProjects: $scope.appProjects
              .filter((appProject) => {
                return appProject.categories.indexOf(resp.id) > -1
              })
              .map((appProject) => appProject.project),
          }
          getQuestionsForCategory(resp)
        })
      }

      function getCategoryList(page) {
        page = page || 1
        Api.Categories.get(
          {
            application: app.id,
            order: 'name',
            is_active: 'True',
            page_size: 250,
            page: page,
          },
          function (resp) {
            $scope.categories = $scope.categories.concat(resp.results)
            if (resp.results.length) {
              if (page == 1) {
                if ($scope.initialCategoryId) {
                  //Dont assign the category, let getParentCustomCategoryById do it.
                } else {
                  //Assign the first cagegory on the first page.
                  const cat = resp.results[0]
                  $scope.category = {
                    ...cat,
                    activeProjects: $scope.appProjects
                      .filter((appProject) => {
                        return appProject.categories.indexOf(cat.id) > -1
                      })
                      .map((appProject) => appProject.project),
                  }
                  $scope.changePage()
                }
              }
            }
            if (resp.next) {
              getCategoryList(++page)
            }
          },
        )
      }

      function getAppProjects(page) {
        Api.AppProjects.get(
          {
            app: app.id,
            page: page,
            is_active: 'True',
            only_project: true,
            page_size: 200,
          },
          (resp) => {
            $scope.appProjects = $scope.appProjects.concat(resp.results)
            if (resp.next) {
              getAppProjects(++page)
            } else {
              if ($scope.initialCategoryId) {
                getParentCustomCategoryById($scope.initialCategoryId)
              }
              getCategoryList(1)
            }
          },
        )
      }

      $scope.is_custom_category = false

      function getQuestionsForCategory(category) {
        if (category.client) {
          //Then it is a custom category.
          $scope.is_custom_category = true
          //We need to walk backwards from ClientAppQuestionsCategories to get the items by order.
          Api.ClientAppQuestionsCategories.get(
            {
              category: category.id,
              search: $scope.query,
              page: $scope.pageNumber,
              order: 'order',
              is_active: 'True',
            },
            function (resp) {
              $scope.client_app_question_categories = resp.results
              $scope.questionCount = resp.count
              $scope.questionTotal = $scope.questionCount

              if (resp.results.length > 0) {
                //Only look for questions if ClientAppQuestionsCategories are found
                //because passing an emptystring for question_ids to the API
                //produces weird results.
                $scope.questionParam = resp.results
                  .map(function (q) {
                    return q.question
                  })
                  .join()

                Api.Questions.get(
                  {
                    question_ids: $scope.questionParam,
                    app_questions: 'True',
                  },
                  function (resp) {
                    $scope.questionList = resp.results
                    getClientAppQuestions()
                  },
                )
              }
            },
          )
        } else {
          $scope.is_custom_category = false
          Api.Questions.get(
            {
              category: category.id,
              page: $scope.pageNumber,
              order: 'order',
              is_active: 'True',
              search: $scope.query,
              app_questions: 'True',
            },
            function (resp) {
              $scope.questionList = resp.results
              $scope.questionCount = resp.count
              $scope.questionParam = $scope.questionList
                .map(function (q) {
                  return q.id
                })
                .join()
              $scope.questionTotal = $scope.questionCount
              getClientAppQuestions()
            },
          )
        }
        updateAddressBar()
      }

      function getClientAppQuestions() {
        var promise = getClientAppQuestionsDefer()
        promise.then(function (results) {
          $scope.questions = $scope.questionList.map(function (q) {
            q.appQuestion = {
              is_active: true,
              custom_categories: [],
              exclude_projects: [],
            }

            results.forEach(function (caq) {
              if (caq.question === q.id) {
                q.appQuestion = caq
                if ($scope.is_custom_category == true) {
                  //Change the normal ordering to the ordering from the client app questions to categories junction table
                  $scope.client_app_question_categories.forEach(function (
                    clientappquestion_to_category,
                  ) {
                    if (clientappquestion_to_category.question == q.id) {
                      q.order = clientappquestion_to_category.order
                      //Store the ID so that we can update the order
                      q.clientappquestion_to_category_id =
                        clientappquestion_to_category.id
                    }
                  })
                }
              }
            })
            return q
          })
        })
      }

      $scope.updateOrder = function (data, clientappquestion_to_category_id) {
        var clientappquestion_to_category = {}
        clientappquestion_to_category.id = clientappquestion_to_category_id
        clientappquestion_to_category.order = data
        Api.ClientAppQuestionsCategories.patch(
          clientappquestion_to_category,
          function (resp) {
            getQuestionsForCategory($scope.category)
          },
        )
      }

      function getClientAppQuestionsDefer() {
        var defer = $q.defer()
        Api.ClientAppQuestions.get(
          { application: app.id, question_ids: $scope.questionParam },
          function (resp) {
            defer.resolve(resp.results)
          },
          function (error) {
            defer.reject(error)
          },
        )
        return defer.promise
      }

      $scope.toggleActive = function (question) {
        if (question.appQuestion.id) {
          Api.ClientAppQuestions.patch(question.appQuestion, function (resp) {})
        } else {
          Api.ClientAppQuestions.post(
            {
              question: question.id,
              is_active: question.appQuestion.is_active,
              application: app.id,
              client: CurrentUser.getClientId(),
            },
            function (resp) {
              question.appQuestion = resp
            },
          )
        }
      }

      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index
      }

      $scope.getActiveProjectsCount = (question) => {
        var count = 0
        for (var j = 0; j < $scope.appProjects.length; j++) {
          if (
            $scope.appProjects[j].categories.indexOf($scope.category.id) !== -1
          ) {
            //then this project is for the currently selected category
            if (
              question.appQuestion.exclude_projects.indexOf(
                $scope.appProjects[j].project.id,
              ) !== -1
            ) {
              //Then the project is in this category, and the project is in the exlude projects
              count += 1
            }
          }
        }
        return $scope.getPossibleProjectsCount() - count
      }

      //For the current category, determine the number of projects that have the category assigned.
      $scope.getPossibleProjectsCount = function () {
        var count = 0
        for (var i = 0; i < $scope.appProjects.length; i++) {
          if (
            $scope.appProjects[i].categories.indexOf($scope.category.id) !== -1
          ) {
            count += 1
          }
        }
        return count
      }

      $scope.changeCategory = () => {
        $scope.questions = []
        $scope.lastExecutedQuery = ''
        $scope.query = ''
        $scope.pageNumber = 1
        $scope.category = {
          ...$scope.category,
          activeProjects: $scope.appProjects
            .filter((appProject) => {
              return appProject.categories.indexOf($scope.category.id) > -1
            })
            .map((appProject) => appProject.project),
        }
        $scope.changePage()
      }

      $scope.changePage = function () {
        getQuestionsForCategory($scope.category)
        //update the URL query parameters without reloading the controller
      }

      function updateAddressBar() {
        $state.transitionTo(
          'app.questions.list',
          {
            categoryId: $scope.category.id,
            app: app.id,
            pageNumber: $scope.pageNumber,
            search: $scope.lastExecutedQuery,
          },
          { notify: false },
        )
      }

      $scope.search = function (query) {
        $scope.lastExecutedQuery = query
        $scope.pageNumber = 1
        getQuestionsForCategory($scope.category)
      }

      $scope.clearSearch = function (query) {
        $scope.lastExecutedQuery = ''
        $scope.query = ''
        getQuestionsForCategory($scope.category)
      }
    },
  )
