import {
  ButtonElement,
  CommonPageHeader,
  useAngularServices,
} from '@/react/components'
import { ObservationNavBar } from '../components/ObservationNavBar'
import { SelectWithSearch } from '../../components/SelectWithSearch'
import { useEffect, useMemo, useState } from 'react'
import { useRouter } from '@/react/hooks'
import { css } from '@emotion/react'

export const AddObservation = () => {
  const { CurrentUser, Api, $rootScope, Notification } = useAngularServices()
  const { stateService } = useRouter()

  const [projectPage, setProjectPage] = useState(1)
  const [projects, setProjects] = useState([])
  const [project, setProject] = useState('')
  const [category, setCategory] = useState('')
  const [categoryPage, setCategoryPage] = useState(1)
  const [categories, setCategories] = useState([])
  const [isButtonDisabled, setButtonDisabled] = useState(false)

  const configurableProjectTitle = useMemo(() => {
    return $rootScope.mobile_apps.find(
      (obj) => obj.id === Number(stateService.params.app),
    ).projects_display
  }, [$rootScope])

  const configurableObservationsTitle = useMemo(() => {
    return $rootScope.mobile_apps.find(
      (obj) => obj.id === Number(stateService.params.app),
    ).observations_display
  }, [$rootScope])

  useEffect(() => {
    const getProjectOptions = async () => {
      const clientId = CurrentUser.getClientId()

      const { data: projectsFromServer } = await Api.get('projects', {
        assigned: true,
        client: clientId,
        deleted: false,
        is_active: 'True',
        page: projectPage,
      })

      if (projectsFromServer.next) {
        setProjectPage(projectPage + 1)
      }

      setProjects((prevOptions) => [
        ...prevOptions,
        ...projectsFromServer.results,
      ])
    }
    getProjectOptions()
  }, [projectPage])

  useEffect(() => {
    const getTemplateOptions = async () => {
      const selectedProject = projects.find((p) => p.name === project)
      if (selectedProject?.id) {
        const { data: appProjectResponse } = await Api.get('app_projects', {
          app: stateService.params.app,
          project: selectedProject?.id,
        })

        const { id: appProjectId } = appProjectResponse.results[0]

        try {
          const { data: categoriesFromServer } = await Api.get('categories', {
            for_app_project: appProjectId,
            order: 'name',
            is_active: true,
            page: categoryPage,
          })

          setCategories((prevCat) => [
            ...prevCat,
            ...categoriesFromServer.results,
          ])

          if (categoriesFromServer.next) {
            setCategoryPage(categoryPage + 1)
          }
        } catch {
          Notification.clearNotification()
          setCategories([])
          setCategoryPage(1)
        }
      }
    }
    getTemplateOptions()
  }, [project, categoryPage])

  const handleNextButton = async () => {
    const selectedCategory = categories.find((t) => t?.name === category)
    const selectedProject = projects.find((p) => p.name === project)

    setButtonDisabled(true)
    try {
      const { data: createdObservation } = await Api.post('observations', {
        application: stateService.params.app,
        category: selectedCategory?.id,
        project: selectedProject?.id,
      })

      stateService.go('app.observations.edit', {
        observation: createdObservation?.id,
      })
    } catch {
      console.log('error')
    }
  }

  const handleCategoriesReset = () => {
    setCategories([])
    setCategoryPage(1)
    setCategory('')
  }

  return (
    <section css={baseStyle}>
      <CommonPageHeader headerText={configurableObservationsTitle} />
      <ObservationNavBar />
      <main className="add__wrapper">
        <div className="top__section">
          <h4 className="section__header">Submit Report</h4>
          <ButtonElement
            text="Submit"
            disabled={!category}
            functionToTrigger={handleNextButton}
            buttonType="submit"
          />
        </div>
        <div className="select__section">
          <SelectWithSearch
            value={project}
            setValue={setProject}
            options={projects}
            title={configurableProjectTitle}
            resetFunction={handleCategoriesReset}
          />
          <SelectWithSearch
            value={category}
            setValue={setCategory}
            options={categories}
            title="Categories"
            disabled={!categories.length || isButtonDisabled}
          />
        </div>
      </main>
    </section>
  )
}

const baseStyle = css({
  main: {
    background: '#fff',
    width: '835px',
  },
  '.top__section': {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '15px',
    paddingInline: '26px',
    paddingBottom: '12px',
    borderBottom: '1px solid #EEEFF3',
  },
  '.select__section': {
    display: 'flex',
    gap: '20px',
    paddingTop: '21px',
    paddingInline: '26px',
    paddingBottom: '50px',
  },
  '.select___title': {
    color: '#575757',
    fontSize: '12px',
    fontWeight: 600,
    marginBottom: '5px',
  },
  '@media(max-width: 1080px)': {
    main: {
      width: '100%',
    },
    '.select__section': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
})
