import { UISref } from '@/react/components/UISref'
import { css } from '@emotion/react'
import classNames from 'classnames'
import viewIcon from '@/assets/icons/view-icon.svg'
import deleteIcon from '@/assets/icons/delete-nav-icon.svg'
import submitIcon from '@/assets/icons/submit-nav-icon.svg'
import settingsIcon from '@/assets/icons/settings-icon.svg'
import { useRouter } from '@/react/hooks'
import React, { useEffect } from 'react'
import { useAngularServices } from '@/react/components'

export const ObservationNavBar = () => {
  const { stateService } = useRouter()
  const { CurrentUser } = useAngularServices()

  const role = CurrentUser.getRole()
  const isTabVisible = role !== 'supervisor' && role !== 'client_user'

  useEffect(() => {
    if (
      role === 'read_only' ||
      role === 'trainee_manager' ||
      role === 'trainee'
    ) {
      stateService.go('trainings.documentation')
    }
  }, [])

  return (
    <div>
      <div className="tab-button-list" css={tabButtonList}>
        <UISref to={'app.observations.list'}>
          <a
            className={classNames('tab', {
              active:
                stateService.current.name === 'app.observations.list' ||
                stateService.current.name === 'app.batch_reports.list',
            })}
          >
            <img className="nav__icon" src={viewIcon} alt="view icon" />
            <p>View</p>
          </a>
        </UISref>
        <UISref to={'app.observations.add'}>
          <a
            className={classNames('tab', {
              active: stateService.current.name === 'app.observations.add',
            })}
          >
            <img className="nav__icon" src={submitIcon} alt="add icon" />
            <p>Submit</p>
          </a>
        </UISref>

        {isTabVisible ? (
          <>
            <UISref to={'app.observations.deleted_observations'}>
              <a
                className={classNames('tab', {
                  active:
                    stateService.current.name ===
                    'app.observations.deleted_observations',
                })}
              >
                <img className="nav__icon" src={deleteIcon} alt="add icon" />
                <p>Deleted</p>
              </a>
            </UISref>
            <UISref to={'app.observation_templates'}>
              <a
                className={classNames('tab', {
                  active:
                    stateService.current.name === 'app.observation_templates',
                })}
              >
                <img className="nav__icon" src={settingsIcon} alt="add icon" />
                <p>Settings</p>
              </a>
            </UISref>
          </>
        ) : null}
      </div>
    </div>
  )
}

const tabButtonList = css({
  width: '100%',
  marginTop: '20px',
  display: 'flex',
  '@media(max-width: 800px)': {
    '.tab': {
      p: {
        display: 'none',
      },
      '.nav__icon': {
        marginInline: '17px !important',
      },
    },
    '.active': {
      p: {
        display: 'flex',
      },
      '.nav__icon': {
        marginLeft: '25px !important',
        marginRight: '10px !important',
      },
    },
  },
  '.tab': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '45px',
    backgroundColor: '#E6E6E6',
    color: 'inherit',
    margin: 0,
    borderRight: '1px solid #D3D3D3',
    '.nav__icon': {
      marginLeft: '25px',
      marginRight: '10px',
      width: '15px',
      height: '15px',
    },
    p: {
      marginLeft: 0,
      marginRight: '25px',
      marginBottom: 0,
    },
  },
  '.active': {
    backgroundColor: 'white',
  },
  '.upload__nav__icon': {
    width: '11px',
    height: '11px',
    marginLeft: '10px',
  },
})
