import * as React from 'react'
import {
  useAngularServices,
  ButtonElement,
  CommonPageHeader,
} from '@/react/components'
import { useRouter } from '@/react/hooks'
import { css } from '@emotion/react'
import pluralize from 'pluralize'

import {
  SingleIdQuestionsElement,
  SignatureElement,
  NotesElement,
  PageHeader,
  fetchData,
  sendForm,
  Wildcard,
  PartyObservedField,
} from './components'
import { UISref } from '@components/UISref'
import {
  ApplicationConfig,
  GeneralSettings,
  GenericOption,
  ObservationBody,
  ReportAnswer,
  ReportQuestion,
  ReportSettings,
  SignatureType,
} from '@/react/types'
import { difference, isEqual } from 'lodash'

const ANSWER = {
  answer: 'n/a',
  deleted: false,
  party_observed: [],
  severity: 1,
  answer_party_observed_answer_list: [],
}

export function CreateEditBase() {
  const { Api, CurrentUser, Notification } = useAngularServices()
  const { stateService } = useRouter()

  const observationID = stateService.params.observation
  const appID = stateService.params.app
  const userId = CurrentUser.getId()

  const [baseObservationData, setBaseObservationData] =
    React.useState<ObservationBody | null>(null)
  const [questionData, setQuestionData] = React.useState<
    ReportQuestion[] | null
  >(null)
  const [settings, setSettings] = React.useState<{
    generalSettings: GeneralSettings
    reportSettings: ReportSettings
  } | null>(null)
  const [wildcard, setWildcard] = React.useState<string>('')
  const [wildcardId, setWildcardId] = React.useState<string | number>('')
  const [memoryState, setMemoryState] = React.useState<{
    [key: string]: ReportAnswer[]
  } | null>(null)
  const [signatures, setSignatures] = React.useState<SignatureType[]>([])
  const [notes, setNotes] = React.useState([])
  const [partyObservedOptions, setPartyObservedOptions] = React.useState<
    GenericOption[]
  >([])
  const [globalPartyObserved, setGlobalPartyObserved] = React.useState<
    GenericOption[]
  >([])
  const [disableButton, setDisableButton] = React.useState<boolean>(false)
  const [saveButtonPressed, setSaveButtonPressed] = React.useState<number>(0)

  const setGlobalPO = (POs: GenericOption[]) => {
    if (!questionData) return

    if (POs > globalPartyObserved) {
      setMemoryState((prevState: { [key: string]: ReportAnswer[] }) => {
        if (!prevState) return
        const newState = { ...prevState }
        questionData.forEach((question) => {
          const newPOs: { value: number | string; label: string }[] =
            difference(POs, globalPartyObserved)
          const answers = newState[question.id]
          answers
            .filter((answer) => answer.answer === 'n/a')
            .forEach((answer) => {
              const alreadyExists = answer.party_observed.some((PO) =>
                isEqual(PO, newPOs[0]),
              )
              if (!alreadyExists) answer.party_observed.push(newPOs[0])
            })
        })
        return newState
      })
    } else {
      setMemoryState((prevState: { [key: string]: ReportAnswer[] }) => {
        const newState = { ...prevState }
        questionData.forEach((question) => {
          const newPOs: { value: number | string; label: string }[] =
            difference(globalPartyObserved, POs)
          if (newPOs.length) {
            const answers = newState[question.id]
            answers
              .filter((answer) => answer.answer === 'n/a')
              .forEach((answer) => {
                const pos = answer.party_observed.findIndex(
                  (el) => el.value === newPOs[0].value,
                )
                answer.party_observed.splice(pos, 1)
              })
          }
        })
        return newState
      })
    }
    setGlobalPartyObserved(POs)
  }

  React.useEffect(() => {
    const requestParams = {
      deleted: '',
      observation: observationID,
      page_size: 1000,
    }
    fetchData(
      Api,
      requestParams,
      observationID,
      userId,
      setPartyObservedOptions,
      setBaseObservationData,
      setQuestionData,
      setNotes,
      setSignatures,
      setMemoryState,
      setSettings,
      ANSWER,
      setWildcard,
      setWildcardId,
    )
  }, [appID, observationID])

  if (!memoryState) return null

  const app: ApplicationConfig = baseObservationData?.application

  return (
    <section css={baseStyle}>
      <CommonPageHeader
        headerText={'Edit ' + pluralize.singular(app.observations_display)}
      />
      <section className="page-wrap">
        <PageHeader
          projectName={baseObservationData.project.name}
          projectVariableName={pluralize.singular(app.projects_display)}
          categoryVariableName={pluralize.singular(app.observations_categories)}
          originator={
            baseObservationData.user.first_name +
            ' ' +
            baseObservationData.user.last_name
          }
          image={baseObservationData.client.company.logo_url}
          categoryName={baseObservationData.category.name}
        />
        <hr />
        <div className="notes-wildcard-row">
          <div className="notes-wildcard-column">
            <NotesElement
              notesVariableName={app.observation_note_header}
              observationVariableName={app.observations_display}
              notes={notes}
              observationID={observationID}
            />
            {settings?.reportSettings.wildcard_active && (
              <Wildcard
                wildcardHeader={settings.reportSettings.wildcard_display}
                wildcard={wildcard}
                setWildcard={setWildcard}
                required={settings.reportSettings.wildcard_required}
              />
            )}
          </div>
          <div className="notes-wildcard-column">
            <PartyObservedField
              options={partyObservedOptions}
              setOptions={setPartyObservedOptions}
              setGlobalPO={setGlobalPO}
              partyObservedVariableFiled={app.observation_party_observed}
            />
          </div>
        </div>
        <hr />
        {questionData.map((question, idx) => {
          return (
            <div key={question.id} className="single-question">
              <SingleIdQuestionsElement
                partyObservedOptions={partyObservedOptions}
                setPartyObservedOptions={setPartyObservedOptions}
                key={idx}
                questionInfo={question}
                memoryState={memoryState}
                setMemoryState={setMemoryState}
                ANSWER={ANSWER}
                app={app}
                saveButtonPressed={saveButtonPressed}
              />
            </div>
          )
        })}
        <SignatureElement signatures={signatures} />
        <div className="bottom-buttons">
          <UISref
            to="app.observations.list"
            params={{
              pageNumber: stateService.params.previousPageNumber,
              search: stateService.params.previousSearch,
              order: stateService.params.previousOrder,
              reverse: stateService.params.previousReverse,
            }}
          >
            <a>
              <ButtonElement text={'Back'} functionToTrigger={() => {}} />
            </a>
          </UISref>
          <ButtonElement
            text={'Save'}
            functionToTrigger={() => {
              sendForm(
                memoryState,
                Api,
                signatures,
                notes,
                observationID,
                stateService,
                Notification,
                setDisableButton,
                wildcard,
                wildcardId,
              )
              setSaveButtonPressed(saveButtonPressed + 1)
            }}
            disabled={
              disableButton ||
              (wildcard.length === 0 &&
                settings?.reportSettings.wildcard_required)
            }
            debounceTimeout={500}
            buttonType={'submit'}
          />
        </div>
      </section>
    </section>
  )
}

const baseStyle = css({
  paddingBottom: '100px',
  '.notes-wildcard-row': {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10,
    '.notes-wildcard-column': {
      width: '48%',
    },
  },
  '.page-wrap': {
    margin: '20px 10px 60px 20px',
    backgroundColor: 'white',
    padding: '15px 20px 20px 20px',
  },
  '.bottom-buttons': {
    a: {
      width: '6%',
    },
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
})
