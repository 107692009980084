import * as React from 'react'
import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import { css } from '@emotion/react'

import { useFetchObservations } from './hooks'
import { TemplateBaseOne } from './template-1'
import { TemplateBaseTwo } from './template-2'
import { TemplateBaseThree } from './template-3'
import { TemplateBaseFour } from './template-4'
import { TemplateBaseFive } from './template-5'

const templateMap = {
  1: TemplateBaseOne,
  2: TemplateBaseTwo,
  3: TemplateBaseThree,
  4: TemplateBaseFour,
  5: TemplateBaseFive,
}

// @ts-ignore
export function ViewBase() {
  const { $rootScope } = useAngularServices()
  const { stateService } = useRouter()

  const [baseObservationData, processedObservationData] = useFetchObservations()
  if (!baseObservationData) return null

  const templateId = baseObservationData.client.report_settings.theme
  const viewNaQuestions =
    baseObservationData.client.report_settings.view_na_questions

  const TemplateComponent = templateMap[templateId]

  let app
  if (stateService.params.key) {
    app = baseObservationData.application
  } else {
    app = $rootScope.mobile_apps.find((obj) => {
      return obj.id === Number(stateService.params.app)
    })
  }

  return (
    <section css={sectionStyle}>
      {TemplateComponent &&
      processedObservationData.hasOwnProperty('no') &&
      baseObservationData ? (
        <TemplateComponent
          baseStyle={
            stateService.params.key ? baseStyle.external : baseStyle.internal
          }
          baseObservationData={baseObservationData}
          processedObservationData={processedObservationData}
          viewNaQuestions={viewNaQuestions}
          app={app}
        />
      ) : null}
    </section>
  )
}

const sectionStyle = css({
  width: '100%',
  height: 'auto',
  paddingTop: '25px',
  paddingBottom: '165px',
  '-webkit-print-color-adjust': 'exact',
  '@media print': { paddingBottom: 0 },
})
const baseStyle = {
  external: css({
    width: '98%',
    minHeight: '200px',
    marginRight: '1%',
    marginLeft: '1%',
    backgroundColor: 'white !important',
    padding: '20px',
    borderRadius: '20px',
    '@media (max-width: 650px)': { padding: '15px' },
    '@media (max-width: 400px)': { padding: '5px' },
    '@media print': { padding: '10px' },
    '.disclaimer': {
      pageBreakInside: 'avoid',
      pageBreakBefore: 'auto',
      pageBreakAfter: 'auto',
      breakInside: 'avoid',
      breakBefore: 'auto',
      breakAfter: 'auto',
    },
  }),
  internal: css({
    width: '98%',
    minHeight: '200px',
    marginRight: '1%',
    marginLeft: '1%',
    backgroundColor: 'white !important',
    padding: '20px',
    '.disclaimer': {
      pageBreakInside: 'avoid',
      pageBreakBefore: 'auto',
      pageBreakAfter: 'auto',
      breakInside: 'avoid',
      breakBefore: 'auto',
      breakAfter: 'auto',
    },
  }),
}
