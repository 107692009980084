import * as React from 'react'
import { css } from '@emotion/react'
import {
  useAngularServices,
  CustomCheckbox,
  dropdownFiledTheme,
} from '@/react/components'
import { default as ReactSelect } from 'react-select'
import { TextInputStyle } from '@screens/components'
import { compare } from '@screens/Observations/CreateEditPage/components/dataLogic'

import { components } from 'react-select'
import { percentageShadeBlendColor } from '@/utils'
import { checkIndex } from '@components/commonStyles'
import { BinDeleteIcon } from '@/react/componentAssets'

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div
          css={css({ display: 'flex', alignContent: 'center', height: '100%' })}
        >
          <CustomCheckbox isFake={true} isDefaultChecked={props.isSelected} />{' '}
          <div
            className="hider"
            css={css({ width: 30, height: 27, marginLeft: -30, zIndex: 10000 })}
          />
          <label
            css={css({
              height: '22px',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '22px',
              marginLeft: 10,
            })}
          >
            {props.label}
          </label>
        </div>
      </components.Option>
    </div>
  )
}

type Props = {
  answersLink?: any
  options: { value: number; label: string }[]
  setOptions: (arg0: { label: string; value: string }[]) => void
  setGlobalPO: (arg0: any[]) => void
  partyObservedVariableFiled: string
  width?: string
}

export const PartyObservedField: React.FC<Props> = ({
  answersLink,
  options,
  setOptions,
  partyObservedVariableFiled,
  setGlobalPO,
  width,
}: Props) => {
  const { Api, CurrentUser } = useAngularServices()
  const userId = CurrentUser.getId()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const [selectedOptions, setSelectedOptions] = React.useState<
    { value: string; label: string }[]
  >([])
  const [dataForOptions, setDataForOptions] = React.useState({})
  const [addPOString, setAddPOString] = React.useState<string>('')
  const [dropdownVisibility, setDropdownVisibility] = React.useState(false)
  const [input, setInput] = React.useState('')

  if (answersLink) {
    answersLink.party_observed = selectedOptions
    answersLink.party_observed_easy_acces_data = dataForOptions
  }

  function makePODataObject(startingArr) {
    const data = {}
    const preSelectedOption = []
    startingArr.forEach((po) => {
      data[po.party_observed.id] = {
        patchId: po.id,
        deleted: po.deleted || null,
      }
      if (!po.deleted) {
        preSelectedOption.push({
          value: po.party_observed.id,
          label: po.party_observed.name,
        })
      }
    })
    setDataForOptions(data)
    setSelectedOptions(preSelectedOption)
  }

  React.useEffect(() => {
    makePODataObject(
      answersLink ? answersLink.answer_party_observed_answer_list : [],
    )
  }, [])

  React.useEffect(() => {
    if (setGlobalPO) setGlobalPO(selectedOptions)
  }, [selectedOptions])

  async function addUserPartyObserved(text: string) {
    if (text === '') return
    let alreadyExists = false
    options.forEach((option) => {
      if (option.label.toLowerCase() === text.toLowerCase())
        alreadyExists = true
    })
    if (alreadyExists) return
    const { data: response } = await Api.post('answer_party_observed', {
      name: text,
      user: userId,
      has_user: true,
    })
    setOptions([...options, { value: response.id, label: text }].sort(compare))
    setSelectedOptions([
      ...selectedOptions,
      { value: response.id, label: text },
    ])
    setDropdownVisibility(false)
  }

  const handleInputChange = (inputValue) => {
    setAddPOString(inputValue)
  }

  const baseStyle = getBaseStyle(variableColor, width)

  return (
    <div css={baseStyle}>
      <div
        className="input-focus-block"
        onClick={(e) => {
          if (!dropdownVisibility) setDropdownVisibility(true)
        }}
      >
        <label>
          {answersLink ? '' : 'Global '}
          {partyObservedVariableFiled}:{' '}
          <a css={css({ color: variableColor, paddingLeft: 15 })}>
            {' '}
            + {partyObservedVariableFiled}
          </a>
        </label>
        {dropdownVisibility || selectedOptions.length === 0 ? (
          <div className="select-holder">
            <ReactSelect
              autoFocus
              onBlur={(e) => {
                if (dropdownVisibility) setDropdownVisibility(false)
                addUserPartyObserved(addPOString)
                setInput('')
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  addUserPartyObserved(addPOString)
                }
              }}
              inputValue={input}
              onInputChange={(value, action) => {
                handleInputChange(value)
                // only set the input when the action that caused the
                // change equals to "input-change" and ignore the other
                // ones like: "set-value", "input-blur", and "menu-close"
                if (action.action === 'input-change') setInput(value) // <---
              }}
              components={{
                Option,
              }}
              defaultValue={selectedOptions}
              menuIsOpen={dropdownVisibility || selectedOptions.length !== 0}
              options={options}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(values) => {
                setSelectedOptions(values)
              }}
              styles={selectStyle(variableColor)}
              theme={(theme) => dropdownFiledTheme(theme, variableColor)}
            />
            <div className="button-holder">
              <label className="submit-po">
                <a>Done</a>
              </label>
              <div className="po-toggle-holder">{'>'}</div>
            </div>
          </div>
        ) : null}
      </div>
      {selectedOptions ? (
        <div>
          {selectedOptions.map((selectedOption, index) => (
            <div className="single-po" key={index}>
              <div className="single-po-text">{selectedOption.label}</div>
              <BinDeleteIcon
                color={variableColor}
                onClick={() => {
                  const newSelected = selectedOptions.filter(
                    (obj) => obj.value !== selectedOption.value,
                  )
                  setSelectedOptions(newSelected)
                }}
              />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}

const selectStyle = (variableColor) => {
  return {
    multiValue: (styles) => {
      return {
        ...styles,
        display: 'none',
      }
    },
    placeholder: (styles) => {
      return {
        ...styles,
        display: 'none',
      }
    },
    indicatorsContainer: (styles) => {
      return {
        ...styles,
        display: 'none',
      }
    },
    menu: (styles) => {
      return {
        ...styles,
        margin: 0,
      }
    },
    option: (styles, state) => {
      return {
        ...styles,
        color: 'inherit',
        cursor: 'pointer',
        backgroundColor: state.isSelected
          ? percentageShadeBlendColor(0.1, variableColor) + ' !important'
          : state.isFocused
          ? percentageShadeBlendColor(0.4, variableColor) + ' !important'
          : checkIndex(state.options, state.value) % 2 === 0
          ? 'white !important'
          : '#F2F3F5 !important',
        border: '1px solid #E6E6E6',
        '*': {
          cursor: 'pointer',
        },
        'label:last-of-type': {
          width: '95%',
          overflow: 'hidden',
          display: 'inline-block',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          color: state.isSelected ? 'white' : 'inherit',
        },
      }
    },
  }
}

function getBaseStyle(variableColor, width = '100%') {
  return css({
    width: width,
    marginRight: '2%',
    a: {
      fontWeight: 'normal',
    },
    '.party-observed-dropdown-trigger': {
      cursor: 'pointer',
      color: variableColor + ' !important',
      marginLeft: '10px',
    },
    '.po-toggle-holder': {
      fontSize: 14,
      color: '#797979',
      transform: 'rotate(90deg) scale(1,2)',
    },
    '.single-po': {
      display: 'flex',
      marginBottom: 20,
      alignItems: 'center',
      cursor: 'pointer',
      '.single-po-text': {
        ...TextInputStyle,
        width: '90%',
        border: 'none',
        wordBreak: 'break-word',
      },
    },
    '.select-holder': {
      position: 'relative',
      cursor: 'pointer',
      '.button-holder': {
        width: 75,
        position: 'absolute',
        right: '2%',
        top: 0,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        img: {
          height: '40%',
          cursor: 'pointer',
        },
        '.submit-po': {
          marginBottom: 0,
          a: {
            color: variableColor,
            textDecoration: 'none',
            marginBottom: 0,
            fontSize: '17px',
          },
        },
      },
    },
  })
}
