import { flatMap } from 'lodash'
import { ReportAnswer, SignatureType } from '@/react/types'

export const fetchData = async (
  Api,
  requestParams,
  observationID,
  userId,
  setPartyObservedOptions,
  setBaseObservationData,
  setQuestionData,
  setNotes,
  setSignatures,
  setMemoryState,
  setSettings,
  ANSWER,
  setWildcard,
  setWildcardId,
) => {
  const [
    { data: observationData },
    { data: generalSettings },
    { data: reportSettings },
    { data: questions },
    { data: answers },
    { data: globalPO },
    { data: userPO },
  ] = await Promise.all([
    Api.Observations.byIDWithoutDeletedParam(observationID),
    Api.GeneralSettings.get(),
    Api.ReportSettings.get(),
    Api.Questions.get(requestParams),
    Api.Answers.get(requestParams),
    Api.get('answer_party_observed', {
      page: 1,
      deleted: false,
      is_active: true,
      page_size: 1000,
      has_user: false,
    }),
    Api.get('answer_party_observed', {
      page: 1,
      deleted: false,
      is_active: true,
      page_size: 1000,
      has_user: true,
      user: parseInt(userId),
    }),
  ])
  const optionsArray = []
  const optionIdToOrder = {}

  globalPO.results.forEach((po, idx) => {
    optionsArray.push({ value: po.id, label: po.name })
    optionIdToOrder[po.id] = idx
  })
  userPO.results.forEach((po, idx) => {
    optionsArray.push({ value: po.id, label: po.name })
    optionIdToOrder[po.id] = optionsArray.length + idx - 1
  })
  setPartyObservedOptions(optionsArray.sort(compare))

  const settingsData = {
    generalSettings: generalSettings.results[0],
    reportSettings: reportSettings.results[0],
  }

  const allQuestions = [...questions.results]
  if (observationData?.questions?.length > questions?.results?.length) {
    const questionIds = questions.results.map((el) => el.id)
    const deletedQuestionIds = observationData?.questions.filter(
      (item) => !questionIds.includes(item),
    )

    for (let i = 0; i < deletedQuestionIds.length; i++)
      await Api.Questions.byIDWithoutDeletedParam(
        deletedQuestionIds[i],
        (resp) => {
          allQuestions.push(resp)
        },
      )
  }
  const answersReset = await answersReFetch(
    Api,
    { results: allQuestions },
    answers,
    requestParams,
    observationID,
  )
  if (
    observationData.observation_wildcards &&
    observationData.observation_wildcards.length
  ) {
    observationData.observation_wildcards.forEach((wildcard) => {
      if (!wildcard.deleted) {
        setWildcard(wildcard.text)
        setWildcardId(wildcard.id)
      }
    })
  }
  setBaseObservationData(observationData)
  setQuestionData(allQuestions)
  setNotes(notesProcessing(observationData.observation_notes))
  setSignatures(signaturesProcessing(observationData.signatures))
  setMemoryState(
    listProcessing(allQuestions, answersReset.results, observationID, ANSWER),
  )
  setSettings(settingsData)
}

export async function sendForm(
  memoryState: {
    [key: string]: ReportAnswer[]
  },
  Api,
  signatures: SignatureType[],
  notes,
  observationID,
  stateService,
  Notification,
  setDisableButton,
  wildcard,
  wildcardId,
) {
  const initialData = flatMap(memoryState)
  setDisableButton(true)
  const dataToSend = []
  const promiseList = []
  const signaturePromiseList = []
  const party_observed = []
  const party_observed_add = []
  initialData.forEach((answer, idx) => {
    if (answer.answer === 'n/a') {
      answer.party_observed = []
      answer.severity = 1
      answer.answer_party_observed_answer_list = []
      answer.notes = []
      answer.corrective_actions = []
      answer.photos = []
      answer.party_observed_easy_acces_data = {}
    }
    delete answer.initialAnswer
    const notes = []
    const corrective_actions = []
    const photos = []
    answer.notes.forEach((note, idx) => {
      if (note.text && !note.deleted) {
        notes.push(note)
      } else if (note.id) {
        promiseList.push(Api.AnswerNotes.delete({ id: note.id }))
      }
    })
    answer.corrective_actions.forEach((corrective_action, idx) => {
      if (!corrective_action.deleted && corrective_action.text) {
        corrective_actions.push(corrective_action)
      } else if (corrective_action.id) {
        promiseList.push(
          Api.CorrectiveActions.delete({ id: corrective_action.id }),
        )
      }
    })
    answer.photos.forEach((photo, idx) => {
      if (!photo.deleted) {
        photos.push(photo.id)
      } else {
        promiseList.push(Api.removeImage(photo.id))
      }
    })
    if (answer.party_observed) {
      answer.party_observed.forEach(async (po, index) => {
        party_observed.push(po.value)
        if (!answer.party_observed_easy_acces_data.hasOwnProperty(po.value)) {
          party_observed_add.push({
            answer: answer.id,
            party_observed: po.value,
          })
        } else if (
          answer.party_observed_easy_acces_data.hasOwnProperty(po.value) &&
          answer.party_observed_easy_acces_data[po.value].deleted
        ) {
          await Api.patch(
            `answer_party_observed_answer/` +
              answer.party_observed_easy_acces_data[po.value].patchId,
            {
              deleted: false,
              party_observed: po.value,
              answer: answer.id,
            },
          )
          delete answer.party_observed_easy_acces_data[po.value]
        }
      })
      Object.entries(answer.party_observed_easy_acces_data).forEach(
        async (entry) => {
          const [key, value] = entry
          if (!party_observed.includes(parseFloat(key))) {
            await Api.patch(
              `answer_party_observed_answer/` +
                answer.party_observed_easy_acces_data[key].patchId,
              {
                deleted: true,
                party_observed: key,
                answer: answer.id,
              },
            )
          }
        },
      )
    }
    const singleAnswer = {
      ...answer,
      notes: notes,
      corrective_actions: corrective_actions,
      photos: photos,
    }
    dataToSend.push(singleAnswer)
  })
  //validation for Issue Resolved
  const validationArray = [true]
  const failedElements = []
  dataToSend.forEach((answer, idx) => {
    if (answer.answer === 'cls') {
      let corrective_actions_string = ''
      answer.corrective_actions.forEach((corrective_action) => {
        corrective_actions_string += corrective_action.text
      })
      validationArray.push(!!corrective_actions_string)
      if (!corrective_actions_string) {
        answer.corrective_actions_error_ref.current.style.display = 'block'
        failedElements.push(answer.ref)
      }
    }
  })

  if (!validationArray.every((v) => v === true)) {
    failedElements[0].current.scrollIntoView()
    Notification.danger(
      "Any 'Issue Resolved' Answers need to have at least one Corrective action answered",
    )
    setDisableButton(false)
    return
  }
  dataToSend.forEach((answer, idx) => {
    delete dataToSend[idx].ref
    delete dataToSend[idx].corrective_actions_error_ref
  })

  if (wildcardId && wildcard) {
    promiseList.push(
      Api.patch('observation_wildcards/' + wildcardId, {
        text: wildcard,
        observation: observationID,
        id: wildcardId,
      }),
    )
  } else if (wildcardId) {
    promiseList.push(
      Api.delete('observation_wildcards/' + wildcardId, {
        observation: observationID,
        id: wildcardId,
      }),
    )
  } else {
    if (wildcard) {
      promiseList.push(
        Api.post('observation_wildcards', {
          observation: observationID,
          text: wildcard,
        }),
      )
    }
  }

  signatures.forEach((signature) => {
    if (signature.deleted) {
      signaturePromiseList.push(Api.removeSignature(signature.id))
    } else if (signature.id) {
      signaturePromiseList.push(Api.Signatures.patch(signature))
    } else {
      signaturePromiseList.push(Api.Signatures.post(signature))
    }
  })
  notes.forEach((note) => {
    if ((note.deleted && note.id) || (note.text === '' && note.id)) {
      promiseList.push(Api.ObservationNotes.delete({ id: note.id }))
    } else if (note.id) {
      promiseList.push(Api.ObservationNotes.patch(note))
    } else if (note.deleted) {
      return
    } else if (note.text) {
      Api.ObservationNotes.post(note)
    }
  })

  await Promise.all([...promiseList])
  const signatureIdArray = []
  await Promise.all([...signaturePromiseList]).then((values) => {
    values.forEach((value) => {
      signatureIdArray.push(value.data.id)
    })
  })
  await Api.post(`observations/` + observationID + `/answers`, {
    answers: dataToSend,
  })
  await Api.post(`answer_party_observed_answer`, party_observed_add)
  await Api.Observations.patch({
    id: observationID,
    signatures: signatureIdArray,
  })
  await Api.put('observations/' + observationID + '/synchronised/', {
    synchronised: true,
  })
  stateService.go('app.observations.list', {
    pageNumber: stateService.params.previousPageNumber,
    search: stateService.params.previousSearch,
    order: stateService.params.previousOrder,
    reverse: stateService.params.previousReverse,
  })
}

function listProcessing(questions, answers, observationID, ANSWER) {
  const questionsFormData = {}

  questions.forEach((question) => {
    questionsFormData[question.id] = [
      { ...ANSWER, observation: observationID, question: question.id },
    ]
  })

  answers.forEach((answer) => {
    const notes = []
    const corrective_actions = []
    const photos = []
    answer.notes.forEach((note) => {
      if (!note.deleted) {
        notes.push({
          id: note.id,
          text: note.text,
          fieldmeta: {
            text: {
              last_updated: answer.date_updated,
              last_updated_by_user_id: note?.user?.id,
            },
          },
        })
      }
    })
    answer.corrective_actions.forEach((corrective_action) => {
      if (!corrective_action.deleted) {
        corrective_actions.push({
          id: corrective_action.id,
          text: corrective_action.text,
          fieldmeta: {
            text: {
              last_updated: corrective_action.date_updated,
              last_updated_by_user_id: corrective_action?.user?.id,
            },
          },
        })
      }
    })
    answer.photos.forEach((photo) => {
      if (!photo.deleted) {
        photos.push({ id: photo.id, image: photo.image })
      }
    })
    questionsFormData[answer.question][answer.copy_sequence_number] = {
      ...ANSWER,
      observation: observationID,
      question: answer.question,
      answer: answer.answer,
      initialAnswer: answer.answer,
      reference: answer.reference,
      party_observed: answer.party_observed,
      severity: answer.severity,
      notes: notes,
      corrective_actions: corrective_actions,
      photos: photos,
      copy_sequence_number: answer.copy_sequence_number,
      answer_party_observed_answer_list:
        answer.answer_party_observed_answer_list,
    }
    if (answer.id) {
      questionsFormData[answer.question][answer.copy_sequence_number].id =
        answer.id
    }
  })
  return questionsFormData
}

function signaturesProcessing(signatures) {
  const processedSignatures = []
  signatures.forEach((signature) => {
    if (!signature.deleted) {
      processedSignatures.push({
        id: signature.id,
        image: signature.image.id,
        url: signature.image.image,
        printed_name: signature.printed_name,
      })
    }
  })
  return processedSignatures
}

function notesProcessing(notes) {
  const processedNotes = []
  notes.forEach((note) => {
    if (!note.deleted) {
      processedNotes.push({
        id: note.id,
        text: note.text,
        observation: note.observation,
      })
    }
  })
  return processedNotes
}

async function answersReFetch(
  Api,
  questions,
  answers,
  requestParams,
  observationId,
) {
  const idOfAnswersThatExist = []
  answers.results.forEach((answer) => {
    idOfAnswersThatExist.push(answer.question)
  })
  const answersArr = []
  questions.results.forEach((question) => {
    if (!idOfAnswersThatExist.includes(question.id)) {
      answersArr.push({
        question: question.id,
        copy_sequence_number: 0,
        observation: observationId,
        corrective_actions: [],
        notes: [],
      })
    }
  })
  await Api.post(`observations/` + observationId + `/answers`, {
    answers: answersArr,
  })
  const { data: newAnswers } = await Api.Answers.get(requestParams)
  return newAnswers
}

export function compare(a, b) {
  if (a.label.toLowerCase() < b.label.toLowerCase()) {
    return -1
  }
  if (a.label.toLowerCase() > b.label.toLowerCase()) {
    return 1
  }
  return 0
}
