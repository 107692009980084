import * as React from 'react'
import { useAngularServices } from '@components/index'
import { css } from '@emotion/react'
import { getBase64Image } from '@/utils/ImageAndPrintProcessing'

export function Header({ headerData }: any) {
  const { CurrentUser } = useAngularServices()

  const app = headerData.application

  const user = CurrentUser.getClientCompany()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const variableCategoryName = app.observations_categories

  const clientInfoStyle = getClientInfoStyle(variableColor)

  const [imageReplaced, setImageReplaced] = React.useState<boolean>(false)
  const [imageUrl, setImageUrl] = React.useState<string>(
    user.logo_url ? user.logo_url : headerData.client.company.logo_url,
  )

  const { Api } = useAngularServices()

  const getNewImage = async () => {
    const image = await getBase64Image(
      Api,
      user.logo ? user.logo : headerData.client.company.logo,
    )
    setImageUrl(image)
    setImageReplaced(true)
  }

  React.useEffect(() => {
    if (!imageReplaced) {
      getNewImage()
    }
  })

  return (
    <section css={headerBaseStyle}>
      <div css={clientInfoStyle}>
        <div className="image-block">
          <img src={imageUrl} alt="image" className="image" />
        </div>
        <div className="header-fields">
          <div className="header-field">
            <label>NAME: </label>
            <span className="blue-info-text">{headerData.project.name}</span>
            <hr />
          </div>
          <div className="header-field">
            <label>{variableCategoryName.toUpperCase()}: </label>
            <span className="blue-info-text">{headerData.category.name}</span>
            <hr />
          </div>
          <div className="header-field">
            <label>PERFORMED BY: </label>
            {headerData.author_name ? (
              <span> {headerData.author_name}</span>
            ) : (
              <span className="blue-info-text">
                {headerData.user.first_name} {headerData.user.last_name}
              </span>
            )}
            <hr />
          </div>
          <div className="header-field">
            <label>SUBMITTED:</label>
            <span className="blue-info-text">
              {headerData.date_range
                ? new Date(
                    headerData.date_range.split(' - ')[0],
                  ).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }) +
                  ' - ' +
                  new Date(
                    headerData.date_range.split(' - ')[1],
                  ).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })
                : new Date(headerData.date_created).toLocaleDateString(
                    'en-US',
                    {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    },
                  )}
            </span>
            <hr />
          </div>
        </div>
      </div>
    </section>
  )
}

const headerBaseStyle = css({
  width: '100%',
})
function getClientInfoStyle(variableColor) {
  return css({
    width: '100%',
    display: 'inline-block',
    height: '200px',
    '.image-block': {
      verticalAlign: 'top',
      width: '30%',
      height: 163,
      display: 'inline-block',
      textAlign: 'center',
      img: {
        maxWidth: '100%',
        maxHeight: '100%',
      },
    },
    '.header-fields': {
      borderLeftStyle: 'solid',
      borderLeftWidth: '1px',
      borderLeftColor: '#eeeeee',
      width: '70%',
      display: 'inline-block',
      paddingLeft: '1%',
      '.header-field': {
        display: 'inline-block',
        width: '40%',
        marginRight: '3%',
        label: {
          display: 'block',
          color: '#A9A9A9 !important',
          fontStyle: 'italic',
          fontWeight: 'bold',
        },
        span: {
          display: 'block',
          fontWeight: 'bold',
        },
        '.orange-info-text': {
          color: 'orange !important',
        },
        '.blue-info-text': {
          color: variableColor + ' !important',
        },
        hr: {
          marginTop: '5px',
        },
      },
    },
  })
}
