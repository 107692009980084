import * as React from 'react'
import { SingleVariableAmountField } from './SingleVariableAmountField'
import { css } from '@emotion/react'
import moment from 'moment'
import { useAngularServices } from '@/react/components'

type Props = {
  typeOfField: string
  answersLink: object[]
  startWithEmptyField?: boolean
  wholeAnswerLink?: any
  isFullScreenWidth?: boolean
  isAllowMultiLine?: boolean
  isAllowDeleteConfirmation?: boolean
  maxLength?: number
  saveDataHandler?: void
  deleteFromServer?: void
  isFieldChanged?: void
  uniqModalId?: string
}

export function FieldsWithVariableAmount({
  typeOfField,
  answersLink,
  startWithEmptyField,
  wholeAnswerLink,
  isFullScreenWidth,
  isAllowMultiLine,
  isAllowDeleteConfirmation,
  maxLength,
  saveDataHandler,
  deleteFromServer,
  uniqModalId,
  isFieldChanged,
}: Props) {
  const [inputList, setInputList] = React.useState([])

  const { CurrentUser } = useAngularServices()
  const user = CurrentUser.getInstance()

  if (wholeAnswerLink)
    wholeAnswerLink.corrective_actions_error_ref = React.useRef()

  function addField(typeOfField, link) {
    setInputList([
      ...inputList,
      <SingleVariableAmountField
        key={inputList.length}
        uniqModalId={uniqModalId}
        typeOfField={typeOfField}
        deleteFromServer={deleteFromServer}
        fieldId={inputList.length}
        isFullScreenWidth={isFullScreenWidth}
        isAllowMultiLine={isAllowMultiLine}
        isAllowDeleteConfirmation={isAllowDeleteConfirmation}
        maxLength={maxLength}
        answersLink={link}
        isFieldChanged={isFieldChanged}
        saveDataHandler={saveDataHandler}
        errorRef={
          wholeAnswerLink ? wholeAnswerLink.corrective_actions_error_ref : null
        }
        isIssueResolved={startWithEmptyField}
      />,
    ])
    link.push({
      text: '',
      fieldmeta: {
        text: {
          last_updated: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          last_updated_by_user_id: user.id,
        },
      },
      deleted: false,
    })
  }

  React.useEffect(() => {
    const initialFields = []
    answersLink?.forEach((initial, idx) => {
      initialFields.push(
        <SingleVariableAmountField
          key={idx}
          typeOfField={typeOfField}
          fieldId={idx}
          uniqModalId={uniqModalId}
          deleteFromServer={deleteFromServer}
          answersLink={answersLink}
          isFullScreenWidth={isFullScreenWidth}
          isAllowMultiLine={isAllowMultiLine}
          isAllowDeleteConfirmation={isAllowDeleteConfirmation}
          maxLength={maxLength}
          saveDataHandler={saveDataHandler}
          isFieldChanged={isFieldChanged}
          errorRef={
            wholeAnswerLink
              ? wholeAnswerLink.corrective_actions_error_ref
              : null
          }
          isIssueResolved={startWithEmptyField}
        />,
      )
    })
    if (initialFields.length) setInputList(initialFields)
  }, [answersLink])

  if (!inputList.length && startWithEmptyField)
    addField(typeOfField, answersLink)

  const cssParams = startWithEmptyField
    ? css({
        '.corrective_action:first-child:last-child .beside-input-button': {
          display: 'none',
        },
      })
    : css({
        marginTop: '15px',
      })

  return (
    <div css={baseStyle} style={isFullScreenWidth && { width: '100%' }}>
      <b>{typeOfField}:</b>
      <a
        className={'ccs-link'}
        onClick={() => {
          addField(typeOfField, answersLink)
        }}
      >
        {' + '}
        {typeOfField}
      </a>
      <div css={cssParams}>{inputList}</div>
      {startWithEmptyField ? (
        <div
          className="attention-text"
          ref={wholeAnswerLink.corrective_actions_error_ref}
        >
          Please add a {typeOfField.toLowerCase()} to resolved issues
        </div>
      ) : null}
    </div>
  )
}

const baseStyle = css({
  width: '48%',
  marginRight: '2%',
  b: {
    marginRight: '15px',
  },
  a: {
    fontWeight: 700,
  },
  '.attention-text': {
    color: 'red',
    display: 'none',
    fontStyle: 'italic',
  },
})
