import * as React from 'react'
import { css } from '@emotion/react'

type Props = {
  projectName: string
  projectVariableName: string
  categoryVariableName: string
  categoryName: string
  originator: string
  image: string
}

export const PageHeader: React.FC<Props> = ({
  projectName,
  projectVariableName,
  categoryVariableName,
  categoryName,
  originator,
  image,
}: Props) => {
  return (
    <section css={baseStyle}>
      <div className="image-holder">
        <img src={image} alt="COMPANY LOGO" />
      </div>
      <div className="h-holder">
        <div className="subheaders">
          <h3 className="bolder">{projectVariableName} Name:</h3>
          <h3>{projectName}</h3>
        </div>
        <div className="subheaders">
          <h3 className="bolder">{categoryVariableName}:</h3>
          <h3>{categoryName}</h3>
        </div>
        <div className="subheaders">
          <h3 className="bolder">Originator:</h3>
          <h3>{originator}</h3>
        </div>
      </div>
    </section>
  )
}

const baseStyle = css({
  display: 'flex',
  paddingRight: '10%',
  '.image-holder': {
    height: '150px',
    width: '25%',
    paddingRight: '1%',
    textAlign: 'center',
    img: {
      maxHeight: '100%',
      maxWidth: '100%',
    },
  },
  '.h-holder': {
    width: '75%',
    display: 'flex',
    justifyContent: 'space-between',
    '.subheaders': {
      paddingTop: 30,
      h3: {
        fontWeight: 400,
      },
      '.bolder': {
        fontWeight: 600,
      },
    },
  },
})
