import * as React from 'react'

import { PartyObservedField, PhotoSection, CopyAlert } from './index'
import { FieldsWithVariableAmount, TextInputStyle } from '@screens/components'
import { css } from '@emotion/react'
import { CopyIconLinestyle } from '@/react/componentAssets'
import {
  dropdownFiledTheme,
  dropdownOptionStyles,
  useAngularServices,
} from '@/react/components'
import {
  ApplicationConfig,
  GenericOption,
  ReportAnswer,
  ReportQuestion,
} from '@/react/types'
import { Dispatch, SetStateAction } from 'react'
import { cloneDeep } from 'lodash'
import { default as ReactSelect } from 'react-select'

type SingleQuestionProps = {
  questionInfo: ReportQuestion
  answersLink: ReportAnswer
  copyField: () => void
  setPartyObservedOptions: Dispatch<SetStateAction<GenericOption[]>>
  partyObservedOptions: GenericOption[]
  app: ApplicationConfig
  saveButtonPressed: number
  memoryState: {
    [key: string]: ReportAnswer[]
  }
  setMemoryState: Dispatch<
    SetStateAction<{ [key: string]: ReportAnswer[] } | null>
  >
  index: number
}

export function SingleQuestion({
  questionInfo,
  memoryState,
  setMemoryState,
  copyField,
  partyObservedOptions,
  setPartyObservedOptions,
  app,
  saveButtonPressed,
  index,
}: SingleQuestionProps) {
  const answerLink = memoryState[questionInfo.id][index]

  if (!answerLink) {
    console.error('Missing answer link, error info:', {
      answersLink_base: answerLink,
      Question_Info: questionInfo,
    })
    return null
  }

  const [answerVariable, setAnswerVariable] = React.useState(
    cloneDeep(answerLink?.answer) || 'n/a',
  )
  const [alert, setAlert] = React.useState([])
  const [openToggle, setOpenToggle] = React.useState(false)
  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  React.useEffect(() => {
    if (!answerLink.notes || !answerLink.notes.length) answerLink.notes = []
    if (!answerLink.reference) answerLink.reference = ''
    if (!answerLink.corrective_actions || !answerLink.corrective_actions.length)
      answerLink.corrective_actions = []
    if (!answerLink.photos || !answerLink.photos.length) answerLink.photos = []
  }, [])

  const [textareaHeight, setTextareaHeight] = React.useState<string | number>(
    '35',
  )

  const options = [
    { value: 'yes', label: app.observation_yes },
    { value: 'no', label: app.observation_follow_up_required },
    { value: 'n/a', label: 'N/A' },
    { value: 'cls', label: app.observation_issue_resolved },
    {
      value: 'pr',
      label: app.observations_pending_review,
      isDisabled: true,
    },
    {
      value: 'pa',
      label: app.observations_pending_approval,
      isDisabled: true,
    },
  ]

  const textareaRef = React.useRef(null)

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      const lineHeight = 15
      const minRows = 1

      const rows = Math.max(
        minRows,
        Math.ceil(textareaRef.current.scrollHeight / lineHeight),
      )
      setTextareaHeight(rows * lineHeight)
    }
  }

  React.useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus()
    }
  }, [])

  React.useEffect(() => {
    if (answerVariable === 'cls' && !answerLink.corrective_actions[0]?.text) {
      setOpenToggle(true)
    }
  }, [saveButtonPressed])

  const commonBaseFields = (
    <section>
      <div className="field-with-variable-amounts">
        <FieldsWithVariableAmount
          typeOfField={app.observation_note_header}
          answersLink={answerLink.notes}
        />
        {answerVariable !== 'yes' ? (
          <FieldsWithVariableAmount
            typeOfField={app.observation_corrective_action_header}
            answersLink={answerLink.corrective_actions}
            startWithEmptyField={answerVariable === 'cls'}
            wholeAnswerLink={answerLink}
          />
        ) : (
          <div css={css({ width: '48%', marginRight: '2%' })} />
        )}
      </div>
      <div className="line-of-inputs">
        <PartyObservedField
          answersLink={answerLink}
          options={partyObservedOptions}
          setOptions={setPartyObservedOptions}
          partyObservedVariableFiled={app.observation_party_observed}
          width={'48%'}
        />
        <div className="reference-body">
          <label>{app.observation_reference}</label>
          <textarea
            className="form__textarea"
            onFocus={adjustTextareaHeight}
            onInput={adjustTextareaHeight}
            ref={textareaRef}
            defaultValue={answerLink.reference || ''}
            onChange={(value) => {
              answerLink.reference = value.target.value
            }}
            style={{ height: `${textareaHeight}px` }}
          />
        </div>
      </div>
      <PhotoSection answerLink={answerLink.photos} />
      <hr />
    </section>
  )

  const severityField = (
    <div className="severity-body">
      <label>Severity: </label>
      <select
        className="select-severity"
        name="answer"
        onChange={(value) => {
          answerLink.severity = value.target.value
        }}
        defaultValue={answerLink.severity || '1'}
      >
        <option value="1">{app.observation_low_severity}</option>
        <option value="2">{app.observation_medium_severity}</option>
        <option value="3">{app.observation_high_severity}</option>
      </select>
    </div>
  )

  answerLink.ref = React.useRef()

  return (
    <div css={baseStyle} ref={answerLink.ref}>
      <div
        className="fullWidth header-answer-holder"
        css={css({ backgroundColor: openToggle ? 'transparent' : '#F3F8FD' })}
      >
        <div
          className="question-header"
          onClick={() => {
            setOpenToggle(!openToggle)
          }}
        >
          <label />
          <h5 dangerouslySetInnerHTML={{ __html: questionInfo.name }} />
        </div>
        <div className="right-header-part">
          <div className="select-answer-holder">
            <ReactSelect
              defaultValue={options.filter((option) => {
                if (option.value === (answerLink.answer || 'n/a')) return option
              })}
              options={options}
              onChange={(value) => {
                setMemoryState(
                  (prevState: { [key: string]: ReportAnswer[] }) => {
                    if (!prevState) return
                    const newState = cloneDeep(prevState)
                    const newAnswerState = newState[questionInfo.id][index]

                    newAnswerState.answer = value.value
                    newAnswerState.severity = 1

                    return newState
                  },
                )

                setAnswerVariable(value.value)
                if (value !== 'n/a') {
                  setOpenToggle(true)
                }
              }}
              styles={dropdownOptionStyles(variableColor)}
              theme={(theme) => dropdownFiledTheme(theme, variableColor)}
            />
          </div>
          <CopyIconLinestyle
            color={variableColor}
            height={'50%'}
            width={'32px'}
            onClick={() => {
              localStorage.getItem('doNotAskAboutCopy')
                ? copyField()
                : setAlert([
                    <CopyAlert callback={copyField} setAlert={setAlert} />,
                  ])
            }}
            additionalStyles={css({
              marginTop: 20,
              marginLeft: 20,
              cursor: 'pointer',
            })}
          />
          <div className="alert-holder">{alert}</div>
          <div
            className="toggle-holder"
            onClick={() => {
              setOpenToggle(!openToggle)
            }}
          >
            <div
              css={[
                css({
                  fontSize: 20,
                  color: variableColor,
                  transform:
                    'rotate(' + (openToggle ? 90 : -90) + 'deg) scale(1,2)',
                }),
              ]}
            >
              {'>'}
            </div>
          </div>
        </div>
      </div>
      <section
        css={css({
          display: openToggle && answerVariable !== 'n/a' ? 'block' : 'none',
          '.severity-body': { display: answerVariable === 'yes' ? 'none' : '' },
        })}
      >
        {severityField}
        {commonBaseFields}
      </section>
      <hr />
    </div>
  )
}

const baseStyle = css({
  '.fullWidth': {
    width: '100%',
  },
  '.header-answer-holder': {
    display: 'flex',
    justifyContent: 'space-between',
    '.question-header': {
      cursor: 'pointer',
      width: 'calc(100% - 265px)',
      paddingLeft: 15,
      paddingRight: 30,
      pageBreakInside: 'avoid',
      pageBreakBefore: 'auto',
      pageBreakAfter: 'auto',
      breakInside: 'avoid',
      breakBefore: 'auto',
      breakAfter: 'auto',
    },
    '.right-header-part': {
      width: 300,
      display: 'flex',
      justifyContent: 'right',
    },
    '.select-answer-holder': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minWidth: 180,
      '.select-answer': {
        height: 34,
        borderColor: '#e5e6e7',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '0',
      },
    },
  },
  '.severity-body': {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
    '.select-severity': {
      width: '20%',
      height: 34,
      minWidth: 160,
      borderColor: '#e5e6e7',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderRadius: '0',
    },
  },
  '.field-with-variable-amounts': {
    marginBottom: 20,
    width: '100%',
    display: 'flex',
  },
  '.line-of-inputs': {
    width: '100%',
    display: 'flex',
    marginBottom: 20,
    '.reference-body': {
      width: '48%',
      marginRight: '2%',
      display: 'flex',
      flexDirection: 'column',
      input: {
        ...TextInputStyle,
      },
    },
  },
  '.alert-holder': {
    width: 0,
    height: 0,
    position: 'relative',
    zIndex: 300,
  },
  '.toggle-holder': {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    cursor: 'pointer',
  },
  '.form__textarea': {
    width: '100%',
    backgroundColor: '#FFFFFF',
    outline: 'none',
    backgroundImage: 'none',
    borderColor: '#e5e6e7',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: 0,
    paddingLeft: '6px',
    paddingRight: '6px',
    paddingTop: '6px',
    paddingBottom: '6px',
    fontSize: '14px',
    minHeight: '30px',
    maxWidth: '100%',
    resize: 'vertical',
  },
})
